import { Link } from "gatsby";
import React from "react";
import MainLogo from "./logo";

const Circle = () => <span className="circle-separator mx-4"></span>;

const Footer = () => {
	return (
		<footer className="main-footer text-sm whitespace-no-wrap">
			<div className="container">
				<section className="flex flex-col items-center sm:flex-row sm:justify-between max-w-screen-xl mx-auto sm:items-start p-3 border-t-2 border-gray-300 py-8 md:py-12">
					<div>
						<div className="flex flex-wrap items-center">
							{/* <Link className="hover:text-brand-orange" to="/impresszum">
								Impresszum
							</Link>
							<Circle /> */}
							<Link className="hover:text-brand-orange" to="/rolunk#kik-vagyunk">
								Kik vagyunk?
							</Link>{" "}
							<Circle />
							<Link className="hover:text-brand-orange" to="/rolunk#celjaink">
								Céljaink
							</Link>
							<Circle />
							<Link className="hover:text-brand-orange" to="/rolunk#tamogatas">
								Támogatás
							</Link>
							<Circle />
							<Link className="hover:text-brand-orange" to="/rolunk#csatlakozas">
								Csatlakozás
							</Link>
						</div>
						<div className="mt-4">mostvasárnap &copy; {new Date().getFullYear()}</div>
					</div>

					<div className="mt-4 md:mt-0">
						<Link to="/">
							<MainLogo />
						</Link>
					</div>
				</section>
			</div>
		</footer>
	);
};

export default Footer;
