import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import { isBrowser } from "../util/helper";
import MainLogo from "./logo";

interface Menu {
	title: string;
	slug: string;
	hash?: string;
}

const MENUS: Menu[] = [
	{ title: "Kampányfőnök", slug: "/kampanyfonok" },
	{ title: "Adattár", slug: "/flex" },
	{ title: "Rólunk", slug: "/rolunk" },
];

const Header = () => {
	const [client, setClient] = useState(false);

	useEffect(() => {
		setClient(true);
	}, []);

	let path = "";
	if (isBrowser()) {
		path = window.location.pathname;
	}
	const [menuVisible, setMenuVisible] = useState(false);

	return (
		<header className="bg-white">
			<div className="container md:flex md:justify-between h-16 top-0 md:items-center select-none">
				<div className="flex items-center h-full w-full justify-between md:p-0">
					<div className="text-4xl font-semibold tracking-wide font-sans text-gray-800">
						<Link to="/">
							<MainLogo multiplier={0.8} />
						</Link>
					</div>
					<div className="md:block"></div>
					<div className="md:hidden flex items-center">
						<button
							onClick={() => setMenuVisible(!menuVisible)}
							type="button"
							className="block text-black focus:outline-none"
						>
							<div className={menuVisible ? "block" : "hidden"}>
								<svg
									className="h-6 w-6 fill-current hover:text-brand-blue"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 20 20"
								>
									<path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"></path>
								</svg>
							</div>
							<div className={menuVisible ? "hidden" : "block"}>
								<div className="flex">
									<svg
										className="h-6 w-6 fill-current hover:text-brand-blue"
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 20 20"
									>
										<path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
									</svg>
								</div>
							</div>
						</button>
					</div>
				</div>
				<nav
					className={`${
						menuVisible ? "block" : "hidden"
					} relative md:flex items-center bg-black md:bg-transparent md:h-full md:pb-0 z-10`}
				>
					{MENUS.map((item, i) => (
						<React.Fragment key={`${item.slug}${client}`}>
							<Link
								to={`${item.slug}${item.hash ? `#${item.hash}` : ""}`}
								className={`relative whitespace-no-wrap py-4 pl-2 md:py-0 md:pl-0 flex items-center hover:text-brand-orange ${
									(item.slug === "/" && (path === "/" || path === "/thissunday/")) ||
									(item.slug !== "/" && path.indexOf(item.slug) > -1)
										? "bg-gray-800 md:bg-white text-white md:text-gray-500"
										: "text-white md:text-black"
								}`}
							>
								<h1 className="font-medium md:mx-2 z-20">{item.title}</h1>
								<div className="absolute bottom-0 left-0 w-full h-0"></div>
							</Link>
							{i < MENUS.length - 1 && <span className="hidden md:block circle-separator mx-4"></span>}
						</React.Fragment>
					))}
				</nav>
			</div>
		</header>
	);
};

export default Header;
