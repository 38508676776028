import React from "react";

const MainLogo = ({ multiplier }: { multiplier?: number }) => {
	const m = (multiplier ?? 1) * 0.4;
	const width = 514 * m;
	const height = 123 * m;
	return (
		<div className="MainLogo">
			<svg width={width} height={height} viewBox="0 0 514 123" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path
					d="M167.355 30.17L167.864 17.5986H167.283L158.895 40.739L150.518 17.5986H149.937L150.447 30.17V50.218H142.07V5.21675H153.48L159.025 20.3712H159.534L165.08 5.21675H175.72V50.218H167.331V30.17H167.355Z"
					fill="black"
				/>
				<path
					d="M207.259 50.989C201.24 50.989 196.808 48.951 193.941 44.8632C191.085 40.7755 189.652 35.0644 189.652 27.7064C189.652 20.3603 191.085 14.6374 193.941 10.5614C196.796 6.47367 201.24 4.43573 207.259 4.43573C213.278 4.43573 217.709 6.47367 220.565 10.5614C223.42 14.6492 224.854 20.3603 224.854 27.7064C224.854 35.0526 223.42 40.7755 220.565 44.8632C217.721 48.951 213.278 50.989 207.259 50.989ZM207.259 43.1926C210.008 43.1926 211.903 42.1736 212.958 40.1238C214.012 38.0858 214.534 35.2303 214.534 31.5809V23.7845C214.534 20.1707 214.001 17.3507 212.958 15.3009C211.903 13.2629 210.008 12.244 207.259 12.244C204.51 12.244 202.602 13.2629 201.548 15.3009C200.493 17.3389 199.972 20.1707 199.972 23.7845V31.652C199.972 35.2659 200.493 38.0858 201.548 40.1238C202.614 42.1736 204.51 43.1926 207.259 43.1926Z"
					fill="black"
				/>
				<path
					d="M254.903 50.9884C251.123 50.9884 247.829 50.3723 245.045 49.1282C242.249 47.8959 239.997 46.2134 238.279 44.0807L243.896 37.8957C245.531 39.6967 247.32 41.0356 249.275 41.8887C251.23 42.7536 253.22 43.1801 255.235 43.1801C257.557 43.1801 259.322 42.6588 260.519 41.6162C261.716 40.5735 262.32 39.0805 262.32 37.1255C262.32 35.526 261.858 34.3055 260.934 33.488C260.01 32.6586 258.457 32.078 256.265 31.7344L251.562 30.9642C247.521 30.277 244.595 28.7841 242.794 26.4854C240.993 24.1868 240.08 21.3905 240.08 18.0848C240.08 13.7482 241.502 10.395 244.334 8.00158C247.166 5.62001 251.147 4.42331 256.265 4.42331C259.749 4.42331 262.782 4.9565 265.353 6.02288C267.925 7.08926 270.01 8.53477 271.61 10.3713L266.135 16.4852C264.927 15.1582 263.517 14.1155 261.882 13.3572C260.247 12.6107 258.398 12.2315 256.337 12.2315C252 12.2315 249.82 14.0325 249.82 17.6345C249.82 19.1985 250.306 20.3716 251.277 21.1417C252.237 21.9237 253.825 22.5043 256.017 22.8953L260.721 23.7366C264.37 24.3764 267.19 25.7508 269.169 27.8599C271.147 29.9689 272.131 32.8008 272.131 36.3554C272.131 38.4644 271.764 40.3957 271.029 42.1849C270.294 43.9622 269.204 45.5143 267.747 46.8177C266.289 48.1329 264.488 49.1519 262.368 49.8746C260.223 50.6211 257.747 50.9884 254.903 50.9884Z"
					fill="black"
				/>
				<path
					d="M308.816 13.1435V50.218H299.147V13.1435H286.185V5.21675H321.778V13.1435H308.816Z"
					fill="black"
				/>
				<path
					d="M155.232 119.297L141.89 74.2953H147.625L154.012 96.7959L158.846 113.822H159.166L164 96.7959L170.386 74.2953H175.932L162.59 119.297H155.232Z"
					fill="black"
				/>
				<path
					d="M218.931 119.297L215.127 106.464H199.333L195.53 119.297H189.925L203.717 74.2953H210.874L224.666 119.297H218.931ZM207.521 80.03H206.94L200.625 101.76H213.848L207.521 80.03Z"
					fill="black"
				/>
				<path
					d="M255.486 120.066C251.446 120.066 248.14 119.319 245.557 117.814C242.974 116.31 240.853 114.402 239.171 112.08L243.105 108.857C244.87 111.049 246.73 112.684 248.685 113.762C250.64 114.84 252.975 115.374 255.676 115.374C258.946 115.374 261.435 114.627 263.153 113.134C264.871 111.641 265.736 109.544 265.736 106.854C265.736 104.674 265.108 102.98 263.863 101.759C262.619 100.539 260.439 99.6741 257.347 99.1646L252.323 98.3234C250.131 97.9324 248.294 97.3518 246.813 96.5816C245.332 95.8115 244.135 94.8754 243.235 93.7735C242.334 92.6716 241.695 91.4749 241.304 90.1597C240.913 88.8445 240.723 87.4819 240.723 86.0601C240.723 81.9249 242.074 78.8087 244.787 76.6996C247.5 74.5906 251.15 73.536 255.747 73.536C259.361 73.536 262.394 74.1522 264.871 75.3607C267.347 76.5811 269.326 78.2874 270.83 80.4912L267.027 83.7733C265.783 82.1382 264.278 80.8111 262.513 79.7803C260.747 78.7495 258.472 78.2281 255.676 78.2281C252.619 78.2281 250.285 78.8679 248.65 80.1476C247.015 81.4272 246.197 83.3467 246.197 85.9179C246.197 87.9203 246.813 89.5554 248.034 90.8232C249.254 92.0792 251.458 92.9915 254.645 93.5484L259.479 94.3896C261.671 94.7806 263.52 95.3612 265.025 96.1314C266.529 96.9016 267.738 97.8257 268.662 98.904C269.586 99.9822 270.238 101.179 270.629 102.518C271.02 103.857 271.21 105.255 271.21 106.712C271.21 110.883 269.847 114.153 267.11 116.523C264.397 118.881 260.522 120.066 255.486 120.066Z"
					fill="black"
				/>
				<path
					d="M315.639 119.296L311.835 106.464H296.041L292.238 119.296H286.633L300.425 74.2951H307.582L321.374 119.296H315.639ZM304.229 80.0299H303.648L297.333 101.76H310.556L304.229 80.0299ZM304.418 70.622L301.065 69.0699L306.539 58.1098L311.243 60.3611L304.418 70.622Z"
					fill="black"
				/>
				<path
					d="M344.207 119.297H338.793V74.2953H355.167C359.338 74.2953 362.526 75.4328 364.706 77.7077C366.898 79.9827 368 83.1699 368 87.2459C368 90.8953 367.052 93.8219 365.168 96.0139C363.272 98.2059 360.523 99.4737 356.909 99.8173L368.391 119.285H362.324L351.364 100.066H344.207V119.297V119.297ZM355.037 95.5044C357.312 95.5044 359.089 94.9357 360.357 93.7982C361.625 92.6607 362.265 91.0375 362.265 88.9284V85.5752C362.265 83.4661 361.625 81.8429 360.357 80.7054C359.089 79.5679 357.312 78.9992 355.037 78.9992H344.207V95.5044H355.037Z"
					fill="black"
				/>
				<path
					d="M391.849 81.1912H391.399V119.297H386.505V74.2953H394.183L409.527 112.01H409.977V74.2953H414.871V119.297H407.205L391.849 81.1912Z"
					fill="black"
				/>
				<path
					d="M460.701 119.297L456.898 106.464H441.104L437.3 119.297H431.696L445.488 74.2953H452.644L466.436 119.297H460.701ZM449.291 80.03H448.711L442.395 101.76H455.618L449.291 80.03Z"
					fill="black"
				/>
				<path
					d="M483.855 119.297V74.2953H500.23C504.401 74.2953 507.576 75.4328 509.768 77.7077C511.96 79.9827 513.05 83.1699 513.05 87.2459C513.05 91.3337 511.96 94.5091 509.768 96.7841C507.576 99.059 504.389 100.196 500.23 100.196H489.27V119.285H483.855V119.297ZM489.27 95.5044H500.099C502.374 95.5044 504.152 94.9357 505.42 93.7982C506.687 92.6607 507.327 91.0375 507.327 88.9284V85.5752C507.327 83.4661 506.687 81.8429 505.42 80.7054C504.152 79.5679 502.374 78.9992 500.099 78.9992H489.27V95.5044Z"
					fill="black"
				/>
				<path
					d="M107.519 122.744H15.5137C7.11303 122.744 0.300049 115.931 0.300049 107.53V15.5137C0.300049 7.113 7.11303 0.299988 15.5137 0.299988H107.531C115.931 0.299988 122.744 7.113 122.744 15.5137V107.53C122.732 115.943 115.919 122.744 107.519 122.744Z"
					fill="url(#paint0_linear)"
				/>
				<path
					d="M122.732 83.9735V78.6771L115.979 75.3002L122.732 68.5465V61.852L111.512 73.0727L88.4423 61.5321L122.732 44.3871V39.0907L95.1368 52.8825L122.732 25.287V18.5925L83.5133 57.8116L45.1828 0.309998H35.4077L16.3194 38.4983L0.300049 54.5177V61.2122L9.61311 51.8991L0.300049 70.5371V81.1298L20.2058 41.3064L39.2348 22.2774L49.0099 41.8159L40.9528 37.7874C39.8865 37.2542 38.5831 37.5978 37.9196 38.5931L0.300049 95.0164V103.559L40.704 42.9534L52.5408 48.8777L62.6596 69.1271L38.8201 81.0469C38.5949 81.1653 38.3817 81.3075 38.2039 81.4971L3.22666 116.474C4.16271 117.754 5.28833 118.891 6.57984 119.828L41.2846 85.1228L64.7805 73.3808L81.025 105.87C81.3686 106.557 82.0085 107.031 82.7668 107.149C82.8971 107.173 83.0156 107.185 83.146 107.185C83.7739 107.185 84.3782 106.936 84.8166 106.486L112.448 78.8549L122.732 83.9735ZM42.0074 17.218C41.6638 16.5308 41.0239 16.0569 40.2656 15.9384C39.4954 15.8199 38.749 16.0687 38.2158 16.6019L26.9122 27.9056L40.1827 1.36455L76.2263 55.43L56.0717 45.3468L42.0074 17.218ZM59.6027 52.4086L77.8378 61.5321L66.8896 67.0062L59.6027 52.4086ZM83.7976 100.787L69.0223 71.2362L83.146 64.1743L107.981 76.5918L83.7976 100.787Z"
					fill="white"
				/>
				<defs>
					<linearGradient
						id="paint0_linear"
						x1="61.5162"
						y1="122.748"
						x2="61.5162"
						y2="0.312905"
						gradientUnits="userSpaceOnUse"
					>
						<stop stopColor="#2979FF" />
						<stop offset="1" stopColor="#EF6C01" />
					</linearGradient>
				</defs>
			</svg>
		</div>
	);
};
export default MainLogo;
